.container {
  width: 100vw;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  background: #15132c;
}

.title {
  font-family: "Audiowide", cursive !important;
  padding-top: 60px;
  margin-bottom: 60px;
  color: #fff;
  font-size: 28px;
  /* font-weight: bold; */
  text-align: center;
  text-transform: uppercase;
}

.cards {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  /* height: 430px; */
  padding-bottom: 125px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 12px;
}

.card {
  position: relative;
  width: 49.4%;
  height: 180px;
  background: #fff;
}

.name {
  font-size: 20px;
  font-weight: 600;
  padding: 20px;
  text-transform: capitalize;
}

.card img {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  filter: drop-shadow(1px 1px 3px #333) saturate(0.8);
  transition-duration: 400ms;
}

.card:hover img {
  filter: drop-shadow(1px 1px 3px #333) saturate(0.8);
}

.description {
  padding: 0 20px 20px;
  width: 70%;
}

@media (max-width: 1220px) {
  .card {
    position: relative;
    margin: 0 auto;
    width: 90vw;
    max-width: 800px;
    min-height: 140px;
    height: fit-content;
    background: #fff;
  }
  .card img {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
    filter: drop-shadow(1px 1px 3px #333) saturate(0.8);
    transition-duration: 0ms;
  }
}

@media (max-width: 560px) {
  .card {
    position: relative;
    margin: 0 auto;
    width: 90vw;
    max-width: 800px;
    min-height: 140px;
    height: fit-content;
    background: #fff;
  }
  
.name {
  font-size: 20px;
  font-weight: 600;
  padding: 20px 20px 10px 20px;
  text-transform: capitalize;
}

.card img {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  filter: drop-shadow(1px 1px 3px #333) saturate(0.8);
  transition-duration: 0ms;
}

.card:hover img {
  filter: drop-shadow(1px 1px 3px #333) saturate(0.8);
}

.description {
  padding: 0 20px 20px;
  width: 70%;
}
}


@media (max-width: 440px) {

  .card {
    position: relative;
    margin: 0 auto;
    width: 90vw;
    max-width: 800px;
    min-height: 140px;
    height: fit-content;
    background: #fff;
  }
  
  .name {
    font-size: 14px;
    font-weight: 600;
    padding: 20px 20px 10px 20px;
    text-transform: capitalize;
  }

.card img {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 100px;
  height: 100px;
  border-radius: 0%;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  filter: drop-shadow(1px 1px 3px #333) saturate(0.8);
  transition-duration: 0ms;
}

.card:hover img {
  filter: drop-shadow(1px 1px 3px #333) saturate(0.8);
}

.description {
  padding: 0 20px 20px;
  width: 69%;
  font-size: 14px;
}
}

@media (max-width: 340px) {
  .card {
    position: relative;
    margin: 0 auto;
    width: 90vw;
    max-width: 800px;
    min-height: 140px;
    height: fit-content;
    background: #fff;
  }
  
.name {
  max-width: 70vw;
  font-size: 14px;
  font-weight: 600;
  padding: 20px 20px 10px 20px;
  text-transform: capitalize;
}

.card img {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  filter: drop-shadow(1px 1px 3px #333) saturate(0.8);
  transition-duration: 0ms;
}

.card:hover img {
  filter: drop-shadow(1px 1px 3px #333) saturate(0.8);
}

.description {
  padding: 0 20px 20px;
  width: 70%;
  font-size: 12px;
}
}



@media (max-width: 600px) {
  .title {
    font-size: 22px;
  }
}

@media (max-width: 500px) {
  .cards {
    padding-bottom: 100px;
  }
  .title {
    font-size: 18px;
    padding-top: 16px;
    margin-bottom: 30px;
  }
}

@media (max-width: 400px) {
  .cards {
    padding-bottom: 70px;
  }
  .title {
    font-size: 16px;
  }
}

@media (max-width: 350px) {
  .title {
    font-size: 14px;
  }
}