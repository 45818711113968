.container {
  width: 100vw;
  height: 345px;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  background: #fff;
  z-index: 100;
}

.title {
  font-family: "Audiowide", cursive !important;
  position: absolute;
  width: 1220px;
  left: calc(50vw - 610px);
  top: -240px;
  color: #fff;
  font-size: 28px;
  text-align: center;
}

.title p {
  display: inline;
}


.title strong {
  text-transform: uppercase;
}

.cards {
  width: 100%;
  height: 800px;
  background: #fff;
}

.items {
  top: -120px;
  position: relative;
  margin: 70px auto 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1200px;
  padding-bottom: 60px;
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.svg {
  width: 240px;
  margin-bottom: 34px;
  transition-duration: 400ms;
  filter: hue-rotate(37deg) drop-shadow(1px 1px 4px rgb(101, 101, 101))
    saturate(0.3);
}
.item:hover .svg {
  filter: hue-rotate(37deg) drop-shadow(1px 1px 4px rgb(101, 101, 101))
    saturate(0.7);
}
.border {
  width: 270px;
  position: absolute;
  top: -17px;
}
.border_normal {
  animation-name: round;
  animation-duration: 100s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.border_reverted {
  animation-name: round_reverted;
  animation-duration: 100s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.arrow {
  width: 270px;
  position: absolute;
  top: -17px;
}

.itemDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  max-width: 310px;
  min-width: 310px;
  text-align: center;
}

.description {
  position: relative;
  top: -150px;
  left: 0px;
  color: #fff;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.description p {
  background: #15132c;
  font-size: 26px;
  max-width: 1200px;
  min-width: 1200px;
  text-align: center;
  box-shadow: 2px 2px 4px #000000c7;
  padding: 30px 30px;
  box-sizing: border-box;
}

.text {
  color: #333;
  margin-bottom: 6px;
}
.subtext {
  color: #333;
}

@keyframes round {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes round_reverted {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(0px);
  }
}

.mobileMap {
  top: -120px;
  position: relative;
  margin: 70px auto 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 98vw;
  padding-bottom: 60px;
}

.mobileMap img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  filter: drop-shadow(2px 2px 4px rgba(51, 51, 51, 0.733));
}

@media (max-width: 1200px) {
  .title {
    left: 0px;
    width: 100vw;
    padding: 0 10px;
  }
  .description p {
    font-size: 18px;
    width: 80vw;
    max-width: 80vw;
    min-width: 80vw;
    text-align: center;
    padding: 30px 30px;
  }
  
.items {
  width: 90vw;
}
.item {
  transform: scale(0.8);
}

}


@media (max-width: 940px) {
  .item {
    transform: scale(0.7);
    width: 33%;
  }
}
@media (max-width: 840px) {
  .item {
    transform: scale(0.6);
    width: 33%;
  }
}

@media (max-width: 600px) {
  .item {
    transform: scale(0.5);
    width: 33%;
  }
  .title {
    font-size: 22px;
  }
  .description p {
    font-size: 16px;
    width: 80vw;
    max-width: 80vw;
    min-width: 80vw;
    text-align: center;
    padding: 20px 20px 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .title {
    font-size: 18px;
    padding-top: 20px;
  }
  .item {
    transform: scale(0.4);
    width: 33%;
  }
}

@media (max-width: 400px) {
  .title {
    font-size: 16px;
    padding-top: 60px;
    margin-bottom: 0px;
  }
  
  .container {
    width: 100vw;
    height: 235px;
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    background: #fff;
  }

  .description {
    position: relative;
    top: -230px;
    left: 0px;
    color: #fff;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .description p {
    font-size: 14px;
    width: 96vw;
    max-width: 96vw;
    min-width: 96vw;
    text-align: center;
    padding: 10px 20px;
  }
  .item {
    transform: scale(0.3);
    width: 33%;
  }
}

@media (max-width: 350px) {
  .title {
    font-size: 14px;
  }
  .description p {
    font-size: 14px;
    width: 80vw;
    max-width: 80vw;
    min-width: 80vw;
    text-align: center;
    padding: 10px 8px;
  }
}


.slider {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sliderArrow {
  width: 40px;
  filter: drop-shadow(2px 2px 4px rgba(51, 51, 51, 0.589))
}

.arrowLeft {
  transform: scaleX(-1);
  margin-right: 60px;
  cursor: pointer;
}

.arrowRight {
  margin-left: 60px;
  cursor: pointer;
}

@media (max-width: 500px) {
  .items {
    top: -138px;
  }
  .slider  {
    transform: scale(0.9);
  }

  .sliderArrow {
    width: 30px;
    filter: drop-shadow(2px 2px 4px rgba(51, 51, 51, 0.589))
  }

  .arrowLeft {
    transform: scaleX(-1);
    margin-right: 60px;
    cursor: pointer;
  }

  .arrowRight {
    margin-left: 60px;
    cursor: pointer;
  }
  .container {
    height: 290px;
  }
  .description {
    top: -200px;
  }
}
@media (max-width: 450px) {
  .items {
    top: -138px;
  }
  .slider  {
    transform: scale(0.75);
    top: 20px;
  }

  .sliderArrow {
    width: 30px;
    filter: drop-shadow(2px 2px 4px rgba(51, 51, 51, 0.589))
  }

  .arrowLeft {
    transform: scaleX(-1);
    margin-right: 60px;
    cursor: pointer;
  }

  .arrowRight {
    margin-left: 60px;
    cursor: pointer;
  }
  .description {
    top: -230px;
  }
  .container {
    height: 230px;
  }
}
@media (max-width: 430px) {
  .description {
    top: -230px;
  }
  .container {
    height: 280px;
  }
}
@media (max-width: 400px) {
  .description {
    top: -230px;
  }
  .container {
    height: 230px;
  }
}
@media (max-width: 380px) {
  .container {
    height: 250px;
  }
  .slider  {
    transform: scale(0.7);
  }

  .sliderArrow {
    width: 30px;
    filter: drop-shadow(2px 2px 4px rgba(51, 51, 51, 0.589))
  }

  .arrowLeft {
    transform: scaleX(-1);
    margin-right: 60px;
    cursor: pointer;
  }

  .arrowRight {
    margin-left: 60px;
    cursor: pointer;
  }
}
@media (max-width: 330px) {
  .slider  {
    transform: scale(0.6);
  }
  .container {
    height: 270px;
  }
  .description {
    top: -240px;
  }

  .sliderArrow {
    width: 30px;
    filter: drop-shadow(2px 2px 4px rgba(51, 51, 51, 0.589))
  }

  .arrowLeft {
    transform: scaleX(-1);
    margin-right: 60px;
    cursor: pointer;
  }

  .arrowRight {
    margin-left: 60px;
    cursor: pointer;
  }
}
@media (max-width: 300px) {
  .container {
    height: 300px;
  }
}