.container {
  width: 100vw;
  max-width: 1920px;
  padding-top: 20px;
  margin: 0 auto;
  position: relative;
  background: #111114;
}

.grid {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 40px;
  height: 126px;
  display: grid;
  grid-template-columns: 1fr 260px;
  grid-template-rows: 80px 1fr;
  grid-template-areas:
    "contacts logo"
    "contacts socials";
}

.logo {
  grid-area: logo;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

.logo img {
  width: 140px;
  margin-bottom: 5px;
}

.logo .tag {
  color: #c61721;
  font-weight: bold;
  margin-right: 3px;
  font-size: 10px;
  text-transform: uppercase;
}

.contacts {
  grid-area: contacts;
}

.socials {
  grid-area: socials;
  width: 98%;
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.socials img {
  width: 24px;
  height: 24px;
  margin-left: 8px;
}

.list {
  position: relative;
  width: 900px;
  left: -2px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.item {
  display: flex;
  flex-direction: row;
}
.item a:nth-child(3) {
  padding-top: 2px;
  height: 14px;
  margin-left: 12px;
}

.key {
  white-space: nowrap;
  margin-right: 16px;
  margin-bottom: 3px;
  font-size: 15px;
  text-transform: capitalize;
}

.value {
  color: #bbb;
}

.address_title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

@media (max-width: 1200px) {

.list {
  position: relative;
  width: 100%;
  max-width: 900px;
  left: -2px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

}


@media (max-width: 550px) {
  
  .grid {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px 24px 40px;
    height: 126px;
    display: grid;
    grid-template-columns: 1fr 160px;
    grid-template-rows: 80px 1fr;
    grid-template-areas:
      "contacts logo"
      "contacts socials";
  }
  
  .list {
    position: relative;
    width: 100%;
    max-width: 900px;
    left: -2px;
  
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
  }
  
  }

  
@media (max-width: 420px) {
  
  .grid {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px 24px 40px;
    display: grid;
    height: fit-content;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 60px;
    grid-template-areas:
      "contacts contacts"
      "logo socials";
  }
  
  .list {
    font-size: 14px;
  }
  .container {
    height: fit-content;
  }

  .logo {
    padding-top: 80px;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .socials {
    width: 100%;
    padding-top: 80px;
    align-items: flex-end;
    justify-content: flex-end;
  }
  
.socials *:nth-child(1) > a > img {
  margin-left: 0px;
}

}