.container {
  width: 100vw;
  height: 800px;
  /* background: #181536; */
  /* background: #0c0c0c; */
  background: #fff;
  /* background: linear-gradient(
    0deg,
    rgb(8 8 8) 0%,
    rgb(6 6 6) 9%,
    rgb(7 7 8) 22%,
    rgb(24 8 8) 47%,
    rgb(4 4 4) 71%,
    rgb(4 4 4) 94%,
    rgb(0 0 0) 100%
  ); */
}
@media (max-height: 700px) {
  .container {
    position: relative;
    height: 700px;
    overflow: hidden;
  }
}
.burger {
  position: absolute;
  top: 12px;
  right: calc(50vw - 600px);
  cursor: pointer;
  color: #fff;
  display: flex;
  text-transform: capitalize;
}

.burger img {
  margin-left: 12px;
}

.burger p {
  font-size: 15px;
}

.map {
  position: absolute;
  filter: contrast(0.5) drop-shadow(3px 2px 0px #15132c);
  /* opacity: 0.05; */
  opacity: 0.3;
  top: 82px;
  left: -233px;
  width: 1411px;
}

.card {
  position: absolute;
  top: 20px;
  left: calc(50vw - 700px);
  width: 1620px;
  height: 780px;
  background: #151832;
  border-radius: 6px;
  box-shadow: 0px 0px 6px #c9c9c9;
}

.logo {
  position: absolute;
  top: 120px;
  left: calc(50vw - 600px);
  width: 180px;
  margin-top: 8px;
  z-index: 700;
}

.logo img {
  width: 100%;
}

.tag {
  position: absolute;
  top: 180px;
  left: calc(50vw - 596px);
  width: 600px;
  z-index: 700;
}

.tag p {
  color: #c61721;
  font-size: 11px;
  font-weight: 800;
  text-transform: uppercase;
}
.title {
  position: absolute;
  top: 274px;
  left: calc(50vw - 601px);
  width: 600px;
  z-index: 700;
}

.title > p {
  font-family: "Audiowide", cursive;
  /* font-family: "Carter One", cursive; */
  /* font-family: "Francois One", sans-serif; */
  /* font-family: "Sen", sans-serif; */
  /* font-family: "Share Tech Mono", monospace; */
  /* font-family: "Shrikhand", cursive; */
  /* font-family: "Unbounded", cursive; */
  /* font-family: "Viga", sans-serif; */
  /* font-family: "Volkhov", serif; */
  width: 430px;
  font-weight: bold;
  color: #15132c;
  font-size: 28px;
  text-transform: uppercase;
  line-height: 30px;
  margin-bottom: 16px;
}

/* .title p {
  font-family: "Carter One", cursive !important;
  color: #15132c;
  font-size: 28px;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 50px;
} */

.about_title {
  position: absolute;
  top: 400px;
  left: calc(50vw - 598px);
  width: 470px;
  z-index: 700;
}

.about_title p {
  color: #030303;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 12px;
}

.about {
  position: relative;
  top: 0px;
  left: 60;
  width: 410px;
  z-index: 700;
}

.about p {
  color: #030303;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 12px;
}

@media (max-height: 700px) {
  .title {
    top: 234px;
  }
}
.button {
  position: absolute;
  top: 420px;
  left: calc(50vw - 595px);
  width: 240px;
  height: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  background: #015897;
}

.button:hover {
  background: #15132c !important;
}

.details_button {
  position: absolute;
  top: 420px;
  left: calc(50vw - 335px);
  width: 160px;
  height: 50px;
  color: #444;
  font-size: 20px;
  font-weight: 500;
  /* background: #f3e110; */
}

.details_button:hover {
  background: #15132c !important;
  color: #fff !important;
}

.line {
  background: #15132c;
  /* background: #242424; */
  height: 40px;
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 500;
}
.line_transparent {
  /* background: #242424; */
  height: 40px;
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
}

.line_transparent .contacts {
  background: #15132c;
}

.contacts {
  position: absolute;
  left: calc(50vw - 600px);
  top: 0px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
}

.contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
}

.contact > *:nth-child(1) {
  margin-right: 12px;
}
.contact > *:nth-child(2) {
  margin-right: 12px;
}
.contact > *:nth-child(3) {
  margin-right: 24px;
  margin-top: 3px;
}

.header {
  position: absolute;
  top: 60px;
  left: calc(50vw - 600px);
  width: 580px;
  height: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 700;
}

.navigation {
  display: flex;
  flex-direction: row;
  width: 400px;
  height: 100%;
  z-index: 700;
}

.menu_button {
  padding: 0 48px 0 0;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  text-transform: uppercase;
}

.menu_button p {
  color: #444;
}
.menu_button.selected p {
  color: #adadad;
}

.language {
  z-index: 700;
}

.hidable_header {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 80px;
  z-index: 400;
  background: white;
  box-shadow: 1px 1px 5px rgba(31, 31, 31, 0.366);
}

.hidable_header .header {
  top: 51px;
}

.carousel_container {
  position: absolute;
  right: calc(50vw - 650px);
  top: 200px;
  width: 530px;
  min-height: 330px;
  height: 440px;
  max-height: 50vh;
  perspective: 1000px;
  perspective-origin: 50% 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 600;
}

@media (max-height: 700px) {
  .carousel_container {
    top: 180px;
  }
}
.carousel {
  /* transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(334deg)
    rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg)
    skewY(0deg); */
  position: relative;
  height: 100%;
  width: 100%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition-duration: 2000ms;
  transition-property: transform;
  will-change: transform;
}
.carousel_side {
  position: absolute;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  transition-duration: 1000ms;
}

.carousel_side img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.carousel_front {
  transform: translateZ(350px);
}
.carousel_left {
  transform: rotateY(270deg) translateX(-180px);
  transform-origin: center left;
}
.carousel_back {
  transform: translateZ(-180px) rotateY(180deg);
}
.carousel_right {
  transform: rotateY(-270deg) translateX(180px);
  transform-origin: top right;
}
.carousel_bottom {
  transform: rotateX(270deg) translateY(180px);
  transform-origin: bottom center;
}
.carousel_top {
  transform: rotateX(-270deg) translateY(-180px);
  transform-origin: top center;
}

.carousel_controls {
  position: absolute;
  bottom: 10px;
  left: calc(50vw - 50px);
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 700;
}

.carousel_circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: rgb(227, 227, 227);
  cursor: pointer;
  transition-duration: 300ms;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.156);
}

@media (max-height: 650px) {
  .carousel_controls {
    top: 710px;
  }
}
@media (min-height: 800px) {
  .carousel_controls {
    top: 710px;
  }
}

.carousel_circle:hover {
  transform: scale(1.1);
}

.carousel_circle.selected {
  background: #15132c;
  filter: saturate(2);
}

.arrow {
  position: absolute;
  bottom: 40px;
  left: calc(50vw - 600px);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #5a687d;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: up-down;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  cursor: pointer;
}

@media (max-height: 600px) {
  .arrow {
    top: 710px;
  }
}
@media (max-height: 700px) {
  .arrow {
    bottom: 100px;
  }
}
@media (min-height: 800px) {
  .arrow {
    top: 710px;
  }
}

.arrow img {
  margin-right: 1px;
  margin-top: 1px;
}

@keyframes up-down {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
.socials {
  display: flex;
  flex-direction: row;
}

.socials img {
  width: 18px;
  height: 18px;
  margin-right: 6px;
  margin-top: 3px;
}

@media (max-width: 1200px) {
  .contacts {
    position: absolute;
    left: 3vw;
    width: 94vw;
    top: 0px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
  }

.contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
}

.contact > *:nth-child(1) {
  margin-right: 12px;
}
.contact > *:nth-child(2) {
  margin-right: 12px;
}
.contact > *:nth-child(3) {
  margin-right: 24px;
  margin-top: 3px;
}

.header {
  position: absolute;
  top: 60px;
  left: 16px;
  width: 94vw;
  height: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 700;
}

.navigation {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: calc(98vw - 60px);
  height: 100%;
  z-index: 700;
}

  
.logo {
  left: 30px;
}

.tag {
  left: 30px;
}

.title {
  left: 22px;
}

.arrow {
  left: 30px;
}
}


@media (max-width: 550px) {
  .contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
  }
  .burger {
    display: none;
  }
  .menu_button {
    padding: 0 24px 0 0;
    font-size: 12px;
  }
}

@media (max-width: 450px) {
  .contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
  }

  
  .contact > *:nth-child(1) {
    margin-right: 4px;
    /* display: none; */
  }
  .contact > *:nth-child(2) {
    margin-right: 12px;
  }
  .contact > *:nth-child(3) {
    margin-right: 12px;
    margin-top: 3px;
  }
  .menu_button {
    padding: 0 12px 0 0;
    font-size: 12px;
  }
}


@media (max-width: 370px) {
  .contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 10px;
  }

  
  .contact > *:nth-child(1) {
    margin-right: 4px;
    display: none;
  }

  .contact:nth-child(3) {
    display: none;
  }

  .contact > *:nth-child(3) {
    margin-right: 2px;
    margin-top: 3px;
  }

  .language {
    transform: scale(0.6);
    position: relative;
    left: -18px;
  }

  .socials img {
    width: 15px;
    height: 15px;
    margin-right: 6px;
    margin-top: 1px;
  }
  .menu_button {
    padding: 0 4px 0 0;
    font-size: 11px;
  }
  .navigation {
    justify-content: flex-end;
    width: calc(98vw - 90px);
    position: relative;
  }
  .header {
    top: 50px;
  }
  
}

@media (max-width: 900px) {
  .carousel_container {
    right: calc(50vw - (600px));
    transform: scale(0.8);
  }
}
@media (max-width: 800px) {
  .carousel_container {
    right: calc(50vw - (640px));
    transform: scale(0.8);
  }
}

@media (max-width: 480px) {

  .map {
    position: absolute;
    filter: contrast(0.5) drop-shadow(3px 2px 0px #15132c);
    /* opacity: 0.05; */
    opacity: 0.3;
    top: 330px;
    left: -100px;
    width: 600px;
  }
  
  .carousel_container {
    position: absolute;
    right: calc(50vw - (285px));
    top: 380px;
    width: 530px;
    min-height: 330px;
    height: 440px;
    max-height: 50vh;
    perspective: 1000px;
    perspective-origin: 50% 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 600;
    transform: scale(0.4);
  }
  .carousel_controls {
    display: none;
  }

  .logo {
    position: absolute;
    top: 0px;
    left: 0px;
    margin-top: 8px;
    z-index: 700;
  }

  /* .logoContainer {
    position: absolute;
    left: 10vw;
    top: 100px;
    transform: scale(0.6);
  } */

  .logo img {
  }

  .tag {
    top: 60px;
    left: 0px;
  }

  .title {
    position: absolute;
    top: 170px;
    left: 2vw;
    width: 96vw;
    z-index: 700;
  }

  .title > p {
    font-family: "Audiowide", cursive;
    width: 96vw;
    font-weight: bold;
    color: #15132c;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 30px;
    margin-bottom: 16px;
  }

  .about {
    position: relative;
    top: 0px;
    left: 0px;
    width: 80vw;
    z-index: 700;
  }

  .about p {
    color: #030303;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
  }
}


@media (max-width: 370px) {
  /* .logo {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    margin-top: 8px;
    z-index: 700;
  } */
/* 
  .logoContainer {
    position: absolute;
    left: calc(50vw - 65px);
    width: 130px;
    top: 80px;
  } */

  /* .logo img {
  } */
/* 
  .tag {
    top: 48px;
    left: 0px;
    width: 100%;
  }

  .tag p {
    font-size: 8px;
  } */
  .title {
    position: absolute;
    top: 160px;
    left: 2vw;
    width: 96vw;
    z-index: 700;
  }

  .title > p {
    font-family: "Audiowide", cursive;
    width: 90vw;
    font-weight: bold;
    color: #15132c;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 20px;
    margin-bottom: 16px;
  }

  .about {
    position: relative;
    top: 0px;
    left: 0px;
    width: 80vw;
    z-index: 700;
  }

  .about p {
    color: #030303;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 12px;
  }
}

.mobileHome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 700px) {
  .logoContainer {
    position: absolute;
    left: -35px;
    top: 65px;
    transform: scale(0.6);
    padding: 20px;
    width: 200px;
    height: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.9;
  }
  
}

.slider {
  position: relative;
  padding-top: 96px;
  height: 320px;
  width: 100vw;
  overflow: hidden;
  background: white;
}

.sliderImage {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 224px;
  width: 100vw;
  object-fit: cover;
  object-position: center;
  filter: saturate(2);
  opacity: 0;
  transition-duration: 400ms;
}

.sliderCurrent {
  opacity: 1;
}

.mobileHome .logo {
  position: relative;
  top: 8px;
  left: 12px;
  width: 95px;
}

.mobileHome .tag {
  position: relative;
  top: 6px;
  left: 12px;
  width: 180px;
}

.mobileHome .tag p {
  font-size: 7px;
}

.mobileHome .title {
  position: relative;
  padding-top: 20px;
  top: 0px;
  left: 10px;
  width: calc(100vw - 10px);
  height: fit-content;
  z-index: 700;
}

.mobileHome .title > p {
  width: calc(100vw - 10px);
  line-height: 20px;
  font-size: 4.5vw;
}

.mobileHome .about {
  position: relative;
  top: -6px;
  left: 0;
  width: 94vw;
  z-index: 700;
  padding-bottom: 0px;
}

.mobileHome .about p {
  font-size: 16px;
}

@media (max-width: 768px) {
  .container {
    height: fit-content;
  }
  .arrow {
    display: none;
  }
}


.linkedinIcon {
  margin-right: 0px !important;
}