.ant-select-selector {
  background: white !important;
  color: #333;
}

.ant-select-arrow {
  color: #333;
}

.ant-select-selection-item {
  color: #333;
}
