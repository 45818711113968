.container {
  width: 100vw;
  max-width: 1920px;
  margin: 0 auto 16px;
  position: relative;
  /* padding-bottom: 225px; */
}

.title {
  font-family: "Audiowide", cursive !important;
  padding-top: 60px;
  margin-bottom: 60px;
  color: #fff;
  font-size: 28px;
  width: 100%;
  /* font-weight: bold; */
  text-align: center;
  text-transform: uppercase;
}

.images {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 16px;
}

.image {
  position: relative;
  width: 49.6%;
  height: 380px;
  overflow: hidden;
  background: #fff;
}
.image_movable {
  height: 580px;
  transition-duration: 400ms;
  filter: saturate(0.8);
}

.image:hover {
}

.image img {
  width: 100%;
  height: 380px;
  object-fit: cover;
}

.image_movable:hover {
  transform: translateY(-100px);
  filter: saturate(1);
}

.image_movable .line {
    margin: 20px auto;
    width: 20%;
    height: 1px;
    background: #c61721;
  }

.image_movable p {
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #333;
  text-transform: uppercase;
}


@media (max-width: 850px) {

  .image {
    min-width: 100%;
  }
  
}

@media (max-width: 550px) {
  .image {
    height: 180px;
  }

  .image img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    object-position: center;
  }
  
  .image_movable {
    height: 180px;
    transition-duration: 400ms;
    filter: saturate(0.8);
  }

  .image_movable:hover {
    transform: translateX(0);
    filter: saturate(0.8);
  }

  .image_movable .line {
      margin: 14px auto 8px auto;
      width: 20%;
      height: 1px;
      background: #c61721;
    }
  
  .image_movable p {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #333;
    text-transform: uppercase;
  }
}

@media (max-width: 340px) {
  .image_movable p {
    width: 100%;
    text-align: center;
    font-size: 11px;
    color: #333;
    text-transform: uppercase;
  }
}


@media (max-width: 600px) {
  .title {
    padding: 60px 20px 0px;
    font-size: 22px;
  }
}

@media (max-width: 500px) {
  .title {
    font-size: 18px;
    padding-top: 30px;
    margin-bottom: 30px;
  }
}

@media (max-width: 400px) {
  .title {
    max-width: 100vw;
    font-size: 16px;
    padding: 0 10px;
    padding-top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 350px) {
  .title {
    font-size: 14px;
    width: 100vw;
    margin: 0 auto 20px auto;
  }
}