.container {
  margin-right: 20px;
  text-transform: capitalize;
}

.option {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.optionXs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.optionXs img {
  width: 16px;
  height: 11px;
}
.option img {
  margin-right: 12px;
  width: 16px;
  height: 11px;
}