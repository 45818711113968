.container {
  position: relative;
  padding-top: 70px;
  width: 100vw;
  /* height: 700px; */
  min-height: 100vh;
  background: #fff;
  overflow: hidden;
}

.map {
  position: absolute;
  filter: contrast(0.5) drop-shadow(3px 2px 0px #15132c);
  /* opacity: 0.05; */
  opacity: 0.3;
  top: 82px;
  left: 4vw;
  width: 92vw;
}
.line {
  background: #15132c;
  /* background: #242424; */
  height: 40px;
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 500;
}
.line_transparent {
  background: #15132c;
  /* background: #242424; */
  height: 40px;
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
}

.line_transparent .contacts {
  background: #15132c;
}

.contacts {
  position: absolute;
  left: calc(50vw - 600px);
  top: 0px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
}

.contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
}

.contact > *:nth-child(1) {
  margin-right: 12px;
}
.contact > *:nth-child(2) {
  margin-right: 12px;
}
.contact > *:nth-child(3) {
  margin-right: 24px;
  margin-top: 3px;
}

.header {
  position: absolute;
  top: 60px;
  left: calc(50vw - 600px);
  width: 580px;
  height: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 700;
}

.navigation {
  display: flex;
  flex-direction: row;
  width: 400px;
  height: 100%;
  z-index: 700;
}

.menu_button {
  padding: 0 48px 0 0;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  text-transform: uppercase;
}

.menu_button p {
  color: #444;
}
.menu_button.selected p {
  color: #adadad;
}

.language {
  z-index: 700;
}

.title {
  font-family: "Audiowide", cursive !important;
  padding-top: 38px;
  margin-bottom: 30px;
  color: #333;
  font-size: 32px;
  /* font-weight: bold; */
  text-align: center;
  position: relative;
  text-transform: uppercase;
}

.cards {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  /* height: 430px; */
  padding-bottom: 60px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  row-gap: 20px;
}

.card {
  position: relative;
  width: 40.6%;
  min-height: 180px;
  background: #15132c;
  box-shadow: 2px 2px 4px #00000085;
  padding-bottom: 20px;
  color: #fff;
}

.name {
  font-size: 28px;
  font-weight: 600;
  padding: 20px;
  text-transform: capitalize;
}

.flag {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  transition-duration: 400ms;
}

.contact_line {
  padding: 0 30px 10px;
  width: 100%;
  display: flex;
  font-size: 18px;
}

.contact_line strong {
  padding-right: 16px;
  padding-bottom: 12px;
  /* text-transform: capitalize; */
}

.contact_line>*:nth-child(1) {
  padding-right: 16px;
  padding-top: 2px;
  /* text-transform: capitalize; */
}

.contact_line>*:nth-child(3) {
  padding-left: 16px;
  padding-top: 2px;
  transform: scale(1.3);
  cursor: pointer;
  /* text-transform: capitalize; */
}

.contact_line p {
  text-align: left;
}


.socials {
  display: flex;
  flex-direction: row;
  margin-right: 0px !important;
}

.socials img {
  width: 18px;
  height: 18px;
  margin-right: 6px;
  margin-top: 3px;
}

@media (max-width: 1200px) {
  
  .cards {
    width: 96vw;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    /* height: 430px; */
    padding-bottom: 20px !important;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    row-gap: 20px;
  }

  .card {
    position: relative;
    width: 48%;
    min-height: 180px;
    background: #15132c;
    box-shadow: 2px 2px 4px #00000085;
    padding-bottom: 20px;
    color: #fff;
  }
}

@media (max-width: 1000px) {
  
  .cards {
    width: 96vw;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    /* height: 430px; */
    padding-bottom: 60px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    row-gap: 20px;
  }
  
  .card {
    position: relative;
    width: 48%;
    min-height: 180px;
    background: #15132c;
    box-shadow: 2px 2px 4px #00000085;
    padding-bottom: 20px;
    color: #fff;
  }

  .flag {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
    transition-duration: 400ms;
  }
}

@media (max-width: 700px) {
  
  .cards {
    width: 96vw;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    /* height: 430px; */
    padding-bottom: 60px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    row-gap: 20px;
  }
  
  .card {
    position: relative;
    width: 100%;
    min-height: 180px;
    background: #15132c;
    box-shadow: 2px 2px 4px #00000085;
    padding-bottom: 20px;
    color: #fff;
  }

  .flag {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
    transition-duration: 400ms;
  }

  a > p {
    padding-top: 3px;
  }
}

@media (max-width: 600px) {
  .title {
    font-size: 22px;
    font-weight: bold;
  }

  
  .name {
    font-size: 20px;
  }

  .contact_line {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .title {
    font-size: 18px;
  }
}

@media (max-width: 400px) {
  .title {
    font-size: 16px;
  }
}

@media (max-width: 350px) {
  .title {
    font-size: 14px;
    width: 80vw;
    margin: 0 auto 20px auto;
  }
}



@media (max-width: 1200px) {
  .contacts {
    position: absolute;
    left: 3vw;
    width: 94vw;
    top: 0px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
  }

  .contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
  }

  .contact > *:nth-child(1) {
    margin-right: 12px;
  }
  .contact > *:nth-child(2) {
    margin-right: 12px;
  }
  .contact > *:nth-child(3) {
    margin-right: 24px;
    margin-top: 3px;
  }

  .header {
    position: absolute;
    top: 60px;
    left: 16px;
    width: 94vw;
    height: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 700;
  }

  .navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: calc(98vw - 60px);
    height: 100%;
    z-index: 700;
  }
}


@media (max-width: 550px) {
  .contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
  }
  .menu_button {
    padding: 0 24px 0 0;
    font-size: 12px;
  }
}

@media (max-width: 450px) {
  .contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
  }

  
  .contact > *:nth-child(1) {
    margin-right: 4px;
    /* display: none; */
  }
  .contact > *:nth-child(2) {
    margin-right: 12px;
  }
  .contact > *:nth-child(3) {
    margin-right: 12px;
    margin-top: 3px;
  }
  .menu_button {
    padding: 0 12px 0 0;
    font-size: 12px;
  }
}


@media (max-width: 370px) {
  .contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 10px;
  }

  
  .contact > *:nth-child(1) {
    margin-right: 4px;
    display: none;
  }

  .contact:nth-child(3) {
    display: none;
  }

  .contact > *:nth-child(3) {
    margin-right: 2px;
    margin-top: 3px;
  }

  .language {
    transform: scale(0.6);
    position: relative;
    left: -18px;
  }

  .socials img {
    width: 15px;
    height: 15px;
    margin-right: 6px;
    margin-top: 1px;
  }
  .menu_button {
    padding: 0 4px 0 0;
    font-size: 11px;
  }
  .navigation {
    justify-content: flex-end;
    width: calc(98vw - 90px);
    position: relative;
  }
  .header {
    top: 50px;
  }
  
}

.linkedinIcon {
  margin-right: 0px !important;
}