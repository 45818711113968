.container {
  width: 100vw;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  background: #fff;
}

.title {
  font-family: "Audiowide", cursive !important;
  padding-top: 60px;
  margin-bottom: 60px;
  color: #333;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.card {
  width: 100%;
  /* height: 560px; */
  padding-bottom: 60px;
  background: #fff;
}

.clients {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100vw;
  max-width: 1200px;
  margin: 0 auto;
}

.client {
  position: relative;
  /* width: 30%; */
  width: 20%;
  overflow: hidden;
}

.client img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 1220px) {
  .clients {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100vw;
    max-width: 90vw;
    margin: 0 auto;
  }
  .client {
    position: relative;
    /* width: 30%; */
    width: 20%;
    min-width: 140px;
    overflow: hidden;
  }
  .card {
    padding-bottom: 30px;
  }
}

@media (max-width: 600px) {
  .title {
    font-size: 22px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 500px) {
  .title {
    font-size: 18px;
  }
}

@media (max-width: 400px) {
  .title {
    font-size: 16px;
    padding-top: 30px;
    margin-bottom: 20px;
  }
}

@media (max-width: 350px) {
  .title {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .clients {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100vw;
    max-width: 90vw;
    margin: 0 auto;
  }
  .client {
    position: relative;
    /* width: 30%; */
    width: 20%;
    min-width: 100px;
    overflow: hidden;
  }
}