:root {
  --border-radius: 6px;

  --background-filter: #00000017;
  --gradient: linear-gradient(
    114.19deg,
    #000b0e 2.9%,
    rgb(13 19 22 / 58%) 51.42%,
    rgba(61, 76, 114, 0) 90.53%
  );
  --main-gradient: linear-gradient(
    114.19deg,
    #000b0e 2.9%,
    rgb(0 0 0 / 58%) 51.42%,
    rgba(61, 76, 114, 0) 90.53%
  );
  font-family: "Roboto", sans-serif;
  
}

.antd * {
  font-family: "Roboto", sans-serif;
}

*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  background: #15132c;
  /* background: #060606; */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  margin: 0;
  padding: 0;
  color: var(--v-primary-base) !important;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
